import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from './UserContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // 导入自定义主题
import { getAuth, onAuthStateChanged, getIdTokenResult } from 'firebase/auth';
import HomePage from './HomePage';
import UserPage from './UserPage';
import Reportpage from './Reportpage';
import PrescriptionPage from './PrescriptionPage';
import RawDataPage from './RawDataPage';
import Cart from './cart';  // 确保路径正确
import CartPage from './CartPage';  // 确保路径正确

// import LoginRegisterPage from './LoginRegisterPage';
import LoginPage from './LoginPage';
import RegistrationPage from './RegistrationPage';
import NavigationBar from './NavigationBar'; // 确保路径正确
import DiagnosePage from './DiagnoseReportPage';

import OrderPage from './OrderPage';
import ContactPage from './ContactPage';
import ShopPage from './ShopPage';
import Footer from './Footer';
import NetworkManager from './NetworkManager'; // 确保这个路径指向了您的 NetworkManager 类所在的位置

function App() {
  localStorage.removeItem('cart');
  const [cart, setCart] = useState(() => {
    const savedCart = JSON.parse(localStorage.getItem('cart'));
    if (savedCart) {
      const newCart = Object.create(Cart.prototype);
      Object.assign(newCart, savedCart);
      return newCart;
    } else {
      return new Cart();
    }
  });
  const [totalQuantity, setTotalQuantity] = useState(0);
  useEffect(() => {
    console.log('[App] useEffect triggered with dependencies:', { cart });
    setTotalQuantity(cart.getTotalQuantity());
  }, [cart]);
  const handleAddToCart = (product, quantityToBuy) => {
    const newCart = Object.create(Cart.prototype);
    Object.assign(newCart, cart);
    newCart.addItem(product, quantityToBuy);
    setCart(newCart);
  };
  const handleUpdateCart = (sku, quantity) => {
    const newCart = Object.create(Cart.prototype);
    Object.assign(newCart, cart);
    if (quantity === 0) {
      newCart.removeItem(sku);  // 移除商品
    } else {
      newCart.updateQuantity(sku, quantity);  // 更新数量
    }
    setCart(newCart);  // 更新购物车
  };
  const { currentUser, setCurrentUser, setIsSuperAdmin, setUserProfile, userProfile } = useContext(UserContext);
  const firstName = userProfile ? userProfile.firstName : 'Unknown User';
  const displayName = firstName ? (firstName.length > 10 ? `${firstName.slice(0, 10)}...` : firstName) : '';

  const auth = getAuth();
  useEffect(() => {
    
    console.log('[App] Setting up onAuthStateChanged listener');
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('[App] onAuthStateChanged triggered with user:', user);
      if (user) {
        // 设置临时 userProfile
        setUserProfile({
          firstName: '...',
          lastName: '...',
          gender: 'Male',
          birthDate: { _seconds: 1699401600, _nanoseconds: 0 },
          birthTimeZone: "America/Los_Angeles",
          maritalStatus: null,
          accountStatus: null,
          WeightKG: 0.0,
          HeightCM: 0.0,
          BMI: 0.0,
          AddressLine1: null,
          AddressLine2: null,
          AddressCity: null,
          AddressCountry: null,
          AddressProvince: null,
          AddressPostcode: null,
          clinicalUser: null,
          interpretingPhysician: null
        });
        try {
          const userProfile = await NetworkManager.fetchUserDataProfile(user.uid);
          if (userProfile) {
            // 避免重复设置相同用户
            if (!currentUser || currentUser.uid !== user.uid) {
              console.log('[App] Setting currentUser with:', user);
              setCurrentUser(user); // 避免重复设置
            }
            setUserProfile(userProfile);

            const idTokenResult = await getIdTokenResult(user);
            setIsSuperAdmin(!!idTokenResult.claims.superAdmin);
          } 
        } catch (error) {
          console.error('Failed to fetch user profile:', error);
        }
      } else {
        console.log('[App] No user, resetting context');
        setCurrentUser(null);
        setUserProfile(null);
      }
    });

    return () => {
      console.log('[App] Cleaning up onAuthStateChanged listener');
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, setUserProfile, setIsSuperAdmin]);





  return (

    <Router>
      <ThemeProvider theme={theme}>
        <NavigationBar
          currentUser={currentUser}
          displayName={displayName}
          totalQuantity={totalQuantity}
        />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/user" element={<UserPage />} />
          <Route path="/reports" element={<Reportpage />} />
          <Route path="/user-prescription" element={<PrescriptionPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/rawData" element={<RawDataPage />} />
          <Route path="/diagnose" element={<DiagnosePage />} />
          <Route path="/orderPage" element={<OrderPage />} />
          <Route path="/contactus" element={<ContactPage />} />
          <Route path="/shoppage" element={<ShopPage handleAddToCart={handleAddToCart} totalQuantity={totalQuantity} cart={cart} />} />
          <Route path="/cartPage" element={<CartPage handleUpdateCart={handleUpdateCart} totalQuantity={totalQuantity} cart={cart} />} />

        </Routes>
        <Footer />
      </ThemeProvider>
    </Router>
  );
}

export default App;


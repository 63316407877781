import React, { useContext, useEffect, useState } from 'react';
import ReportNavBar from './ReportNavBar';
import { useLocation } from 'react-router-dom';
import NetworkManager from './NetworkManager';
import { UserContext } from './UserContext'; // 导入 UserContext
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Card, CardContent } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh'; // 引入刷新图标
import { useNavigate } from 'react-router-dom';
import DateTimePicker from './MyDateTimePicker.jsx';

function Reportpage() {
    const location = useLocation();
    const { currentUser, isSuperAdmin, adminSearchUserId, setAdminSearchUserId } = useContext(UserContext); // 使用 UserContext
    const [isFechingRecords, setIsFeching] = useState(true);
    const [readyReports, setReadyReports] = useState([]);
    const [relatedUsers, setRelatedUsers] = useState([]);

    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [manualEndTimes, setManualEndTimes] = useState({});

    // Render super admin users or user reports
    useEffect(() => {
        console.log('[Reportpage] useEffect triggered with dependencies:',{isSuperAdmin, currentUser});
        const fetchData = async () => {
            setIsFeching(true);
            try {
                if (isSuperAdmin && currentUser?.uid) {
                    const users = await NetworkManager.fetchUserRelatedPatients(currentUser.uid);
                    setRelatedUsers(users);
                } else if (currentUser?.uid) {
                    const foundReportTimePairs = await generateReportsTimePairs(currentUser.uid);
                    // setreportsTimePairs(foundReportTimePairs || []);
                    setReadyReports(foundReportTimePairs.filter(report => report[2]));
                }
            } catch (error) {
                console.error('Error fetching related users or reports:', error);
                setError('Failed to fetch related users or reports');
            } finally {
                setIsFeching(false);
            }
        };

        fetchData();
    }, [isSuperAdmin, currentUser]);

    // Render selected report when user admin
    useEffect(() => {
        console.log('[Reportpage] useEffect triggered with dependencies:',{isSuperAdmin, currentUser, adminSearchUserId});
        const fetchReports = async () => {
            try {
                if (isSuperAdmin && currentUser?.uid && adminSearchUserId && adminSearchUserId.length > 0) {
                    const foundReportTimePairs = await generateReportsTimePairs(adminSearchUserId);
                    // setreportsTimePairs(foundReportTimePairs || []);
                    setReadyReports(foundReportTimePairs.filter(report => report[2]));
                }
            } catch (error) {
                console.error('Error fetching reports timepair for admin:', error);
                setError('Failed to fetch user reports for admin.');
            }
        };

        fetchReports();
    }, [isSuperAdmin, currentUser, adminSearchUserId]);
    const handleSeeDiagnose = (reportTimePair, index) => {
        console.log('report time pair:', reportTimePair);

        let modifiedReportTimePair = [...reportTimePair];
        // 检查 manualEndTimes 中是否有对应索引的结束时间
        if (manualEndTimes.hasOwnProperty(index)) {
            modifiedReportTimePair[1] = manualEndTimes[index];
        } else if (modifiedReportTimePair[1] === -1) {
            // 如果结束时间是 -1，调整为 startTime + 8 小时或当前时间，取较小者
            let potentialEndTime = modifiedReportTimePair[0] + 8 * 3600; // 加上 8 小时的秒数
            let currentTimeInSeconds = Math.floor(Date.now() / 1000);
            modifiedReportTimePair[1] = Math.min(potentialEndTime, currentTimeInSeconds);
        }

        navigate('/diagnose', { state: { reportTimePair: modifiedReportTimePair } });
    };

    const handleRawData = (reportTimePair, index) => {
        console.log('report time pair:', reportTimePair);

        let modifiedReportTimePair = [...reportTimePair];
        // 同上，检查和修改逻辑
        if (manualEndTimes.hasOwnProperty(index)) {
            modifiedReportTimePair[1] = manualEndTimes[index];
        } else if (modifiedReportTimePair[1] === -1) {
            // 如果结束时间是 -1，调整为 startTime + 8 小时或当前时间，取较小者
            let potentialEndTime = modifiedReportTimePair[0] + 8 * 3600; // 加上 8 小时的秒数
            let currentTimeInSeconds = Math.floor(Date.now() / 1000);
            modifiedReportTimePair[1] = Math.min(potentialEndTime, currentTimeInSeconds);
        }
        navigate('/rawData', { state: { reportTimePair: modifiedReportTimePair } });
        
    };
    const handleDelete = async (reportTimePair) => {
        console.log('report time pair:', reportTimePair);
        let userId = currentUser.uid; // 使用当前用户的ID
        if (isSuperAdmin && adminSearchUserId && adminSearchUserId.length > 0) {
            userId = adminSearchUserId;
        }
        const startTimeStamp = reportTimePair[0]; // 从reportTimePair数组中获取开始时间戳

        try {
            // 调用 deleteUserReportTimePair 函数，等待删除操作完成
            await NetworkManager.deleteUserReportTimePair(userId, startTimeStamp);
            console.log('Report time pair successfully deleted.');

            // 删除成功后，你可能需要更新UI或状态
            refreshReports();
        } catch (error) {
            console.error('Error deleting report time pair:', error);
            // 处理可能的错误，比如显示错误消息给用户
        }

    };
    const refreshReports = async () => {
        try {
            setIsFeching(true);
            if (currentUser && !isSuperAdmin) {

                generateReportsTimePairs(currentUser.uid)
                    .then(reportTimePairs => {
                        // setreportsTimePairs(reportTimePairs || []);
                        setReadyReports(reportTimePairs.filter(report => report[2]));
                    })
                    .catch(error => {
                        console.error('Error fetching reports timepair:', error);
                        // 这里处理可能发生的任何错误
                        setError('Failed to fetch user reports');
                    })
                    .finally(() => {
                        // 无论成功或失败，都会执行的逻辑
                        setIsFeching(false);
                    });
            }
            else if (isSuperAdmin && adminSearchUserId && adminSearchUserId.length > 0) {
                const reportTimePairs = await generateReportsTimePairs(adminSearchUserId);
                // setreportsTimePairs(reportTimePairs || []);
                setReadyReports(reportTimePairs.filter(report => report[2]));
            }
        } catch (error) {
            console.error('Error fetching reports timepair:', error);
            setError('Failed to fetch user reports');
        } finally {
            setIsFeching(false);
        }

    };
    // async function generateReportsTimePairs0(userId) {
    //     // 假设最早时间为当前时间的一年前，这里仅为示例，根据需要调整
    //     const searchTo = new Date().getTime();
    //     try {
    //         // 调用 fetchUserReportTimePairs 方法
    //         const reportTimePairs = await NetworkManager.fetchUserReportTimePairs(userId, searchTo.toString());
    //         // 处理或返回获取到的报告时间对
    //         console.log(reportTimePairs);
    //         return reportTimePairs;
    //     } catch (error) {
    //         console.error('Error generating report time pairs:', error);
    //         throw error;
    //     }
    // }
    // const handleAdminView = async () => {
    //     if (!adminSearchUserId || adminSearchUserId.length===0) {
    //         setError('Please enter user ID.');
    //         return;
    //     }
    //     setAdminSearchUserId(adminSearchUserId);
    //     setIsFechingRecords(true);

    //     try {
    //         const reportTimePairs = await NetworkManager.fetchUserReportTimePairs(adminSearchUserId);
    //         setreportsTimePairs(reportTimePairs || []);
    //         setReadyReports(reportTimePairs.filter(report => report[2]));
    //         setError(null);
    //     } catch (error) {
    //         console.error('Error fetching reports timepair for admin:', error);
    //         setError('Failed to fetch user reports for admin.');
    //     } finally {
    //         setIsFechingRecords(false);
    //     }
    // };
    async function generateReportsTimePairs(userId) {
        const searchTo = new Date().getTime();
        const isTimestampInSeconds = (timestamp) => timestamp < 10000000000;
        const convertToMilliseconds = (timestamp) => timestamp * 1000;
        try {
            const reportTimePairs = await NetworkManager.fetchUserReportTimePairs(userId, searchTo.toString());

            const filteredReportTimePairs = reportTimePairs.filter((pair) => {
                let startTime = pair[0];
                let endTime = pair[1];

                if (isTimestampInSeconds(startTime)) {
                    startTime = convertToMilliseconds(startTime);
                }
                if (endTime !== -1 && isTimestampInSeconds(endTime)) {
                    endTime = convertToMilliseconds(endTime);
                }

                // 如果结束时间是-1，或开始和结束时间的差大于等于60秒，则保留该报告
                return endTime === -1 || (endTime - startTime) >= 60000;
            });

            return filteredReportTimePairs;
        } catch (error) {
            console.error('Error generating report time pairs:', error);
            throw error;
        }
    }
    const handleExpand = (userId) => {
        setAdminSearchUserId(prevId => (prevId === userId ? '' : userId));
    };
    return (
        <div style={{ marginTop: '217px' }}>

            <ReportNavBar currentPath={location.pathname} />
            <Container component="main" maxWidth="false" style={{ padding: '20px' }}>

                {/* 病人简历 Section */}
                <Card style={{ marginBottom: '20px', backgroundColor: '#f8f0ff' }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Diagnosed Reports
                        </Typography>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '400px' }}>
                            <Typography variant="h2" component="h3" gutterBottom style={{
                                // fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                textAlign: "left",
                                color: '#0db067',
                                margin: '20px'
                            }}>
                                {isSuperAdmin ? (
                                    <>
                                        You have {(relatedUsers && relatedUsers.length) || 0} patients<br />
                                        Select a patient to see their reports
                                    </>
                                ) : (
                                    <>
                                        You have {(readyReports && readyReports.length) || 0} reports<br />
                                        Record audio to generate a report
                                    </>
                                )}
                            </Typography>

                            <Button
                                variant="outlined" // 使用边框按钮
                                onClick={refreshReports}
                                disabled={isFechingRecords}
                                startIcon={<RefreshIcon />} // 直接使用图标
                                style={{
                                    borderColor: 'blue', // 蓝色边框
                                    color: 'blue', // 蓝色文字
                                    backgroundColor: 'transparent', // 透明背景
                                }}
                            >
                                Refresh Reports
                            </Button>
                        </div>
                        {isSuperAdmin ? (
                            <TableContainer component={Paper} style={{ width: '100%', marginBottom: '20px', backgroundColor: '#fff' }}>
                                {relatedUsers.map((patient, index) => (
                                    <div key={index} style={{ marginBottom: '10px' }}>
                                        <Card>
                                            <CardContent style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>
                                                    <Typography variant="h6">{patient.displayName}</Typography>
                                                    <Typography variant="body2" color="textSecondary">{patient.gender}</Typography>
                                                </div>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleExpand(patient.uid)}
                                                >
                                                    {adminSearchUserId === patient.uid ? 'Hide Reports' : 'View User Reports'}
                                                </Button>
                                            </CardContent>
                                            {adminSearchUserId === patient.uid && (
                                                <div style={{ padding: '10px' }}>
                                                    {readyReports && readyReports.length > 0 ? (
                                                        <Table>
                                                            <TableBody>
                                                                {readyReports.map((reportTimePair, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell>
                                                                            <div style={{ marginRight: 'auto' }}>
                                                                                {`Report  ${new Date(reportTimePair[0] * 1000).toLocaleString()} ----To ---- `}
                                                                                {reportTimePair[1] !== -1
                                                                                    ? new Date(reportTimePair[1] * 1000).toLocaleString()
                                                                                    : <DateTimePicker reportTimePair={reportTimePair} index={index} onEndTimeChange={(date, index) => {
                                                                                        const endTime = date.getTime() / 1000;
                                                                                        setManualEndTimes({ ...manualEndTimes, [index]: endTime });
                                                                                    }}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            <div style={{ marginRight: '20px' }}>
                                                                                Duration: {calculateDuration(reportTimePair[0], reportTimePair[1])}
                                                                            </div>
                                                                            <div>
                                                                                <Button onClick={() => handleSeeDiagnose(reportTimePair, index)} variant="outlined" color="primary">
                                                                                    Sleep Report
                                                                                </Button>
                                                                                <Button onClick={() => handleRawData(reportTimePair, index)} variant="outlined">
                                                                                    Raw Data
                                                                                </Button>
                                                                                <Button onClick={() => handleDelete(reportTimePair)} variant="outlined" color="secondary">
                                                                                    Delete
                                                                                </Button>
                                                                            </div>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    ) : (
                                                        <p>No reports available for this patient. Only reports with a duration greater than 1 minute will be shown. </p>
                                                    )}
                                                </div>
                                            )}
                                        </Card>
                                    </div>
                                ))}
                            </TableContainer>
                        ) : (<TableContainer component={Paper} style={{ width: '60%', marginBottom: '20px', backgroundColor: '#fff' }}>
                            <div id='subView' style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>
                                {isFechingRecords ? (
                                    <p>Loading...</p>
                                ) : error ? (
                                    <p>Error: {error}</p>
                                ) : readyReports ? (
                                    <Table aria-label="simple table">
                                        <TableHead>
                                        </TableHead>
                                        <TableBody>

                                            {readyReports.map((reportTimePair, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div style={{ marginRight: 'auto' }}>
                                                            {`Report  ${new Date(reportTimePair[0] * 1000).toLocaleString()} ----To ---- `}
                                                            {reportTimePair[1] !== -1
                                                                ? new Date(reportTimePair[1] * 1000).toLocaleString()
                                                                : <DateTimePicker reportTimePair={reportTimePair} index={index} onEndTimeChange={(date, index) => {
                                                                    // 在这里处理时间改变逻辑
                                                                    // 例如，更新 manualEndTimes 状态
                                                                    const endTime = date.getTime() / 1000;
                                                                    setManualEndTimes({ ...manualEndTimes, [index]: endTime });
                                                                }}
                                                                />
                                                            }
                                                        </div>
                                                        <div style={{ marginRight: '20px' }} >
                                                            Duration: {calculateDuration(reportTimePair[0], reportTimePair[1])}
                                                        </div>
                                                        <div>
                                                            <Button onClick={() => handleSeeDiagnose(reportTimePair, index)} variant="outlined" color="primary">
                                                                Sleep Report
                                                            </Button>
                                                            <Button onClick={() => handleRawData(reportTimePair, index)} variant="outlined">
                                                                Raw Data
                                                            </Button>
                                                            <Button onClick={() => handleDelete(reportTimePair)} variant="outlined" color="secondary">
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                ) : (
                                    <p>No user data available.</p>
                                )}
                            </div>
                        </TableContainer>)}

                    </CardContent>
                </Card>



            </Container>
        </div>
    );
}
function calculateDuration(startTime, endTime) {
    if (endTime === -1) return 'Please select';

    const duration = (endTime - startTime);
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);

    return `${hours} hr ${minutes} min`;
}
export default Reportpage;

import React, { useState, useEffect } from 'react';
import { Typography, TextField, IconButton, Snackbar, Alert, Button } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ACTIVITY_TRACKING_black from './assets/ACTIVITY_TRACKING_black_for_round.svg';
import FALL_DETECTION_black from './assets/FALL_DETECTION_black.svg';
import HEART_HEALTH_black from './assets/HEART_HEALTH_black.svg';
import SLEEP_QUALITY_black from './assets/SLEEP_QUALITY_black.svg';
import AddIcon from '@mui/icons-material/AddBox';
import RemoveIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useNavigate } from 'react-router-dom';
const CartPage = ({ cart, handleUpdateCart, totalQuantity }) => {
    const [cartItems, setCartItems] = useState(cart.getCartItems());
    const [openSnackbar, setOpenSnackbar] = useState(false); // 控制 Snackbar 提示
   
    const navigate = useNavigate();
    // 当购物车发生变化时同步更新
    useEffect(() => {
        console.log('[CartPage] useEffect triggered with dependencies:', { cart });
        setCartItems(cart.getCartItems());
    }, [cart]);
    const handleShopnowClick = () => {
        navigate('/ShopPage'); // 点击 logo 时导航回主页
    };
    // 更新商品数量
    const handleQuantityChange = (sku, quantity) => {
        if (quantity < 1) {
            // 如果数量小于1，可以考虑自动删除
            handleRemoveItem(sku);
        } else {
            // 调用更新函数
            handleUpdateCart(sku, quantity);
        }
    };
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false); // 关闭 Snackbar 提示
    };
    // 删除商品
    const handleRemoveItem = (sku) => {
        handleUpdateCart(sku, 0); // 0 表示移除商品
    };

    return (
        <div id="Page0" style={{ width: '100%' }}>
            <div style={{ padding: '20px', marginTop: '120px' }}>
                <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold' }}>
                    Your Cart ({totalQuantity} items)
                </Typography>
                {cartItems.length === 0 ? (
                    <Typography variant="h5" style={{
                        fontSize: '30',
                        marginTop: '10vh',
                        marginBottom: '10vh'
                    }}>Shopping cart is empty</Typography>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            {/* 表格头部 */}
                            <TableHead>
                                <TableRow>
                                    <TableCell>ITEM</TableCell>
                                    <TableCell>PRICE</TableCell>
                                    <TableCell>QUANTITY</TableCell>
                                    <TableCell>TOTAL</TableCell>
                                    <TableCell></TableCell> {/* 空白列用于删除按钮 */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cartItems.map((item) => (
                                    <TableRow key={item.product.sku}>
                                        {/* 商品名称和图片 */}
                                        <TableCell>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={item.product.image} alt={item.product.name} style={{ width: '80px', marginRight: '20px' }} />
                                                <Typography variant="h6">{item.product.name}</Typography>
                                            </div>
                                        </TableCell>

                                        {/* 商品单价 */}
                                        <TableCell>
                                            <Typography variant="body1">${item.product.priceCAD}</Typography>
                                        </TableCell>

                                        {/* 数量调整 */}
                                        <TableCell>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                {/* 减号按钮 */}
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => handleQuantityChange(item.product.sku, item.quantity - 1)}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>

                                                {/* 数量输入框 */}
                                                <TextField
                                                    type="number"
                                                    value={item.quantity}
                                                    onChange={(e) => {
                                                        const value = Math.max(0, Math.min(Number(e.target.value), 10000)); // 限制值在 0 到 10000 之间
                                                        handleQuantityChange(item.product.sku, value);
                                                    }}
                                                    onFocus={(e) => e.target.select()}
                                                    inputProps={{
                                                        min: 0,
                                                        max: 10000,
                                                        style: { textAlign: 'center' },
                                                        inputMode: 'numeric',
                                                        pattern: '[0-9]*',
                                                        sx: {
                                                            '&::-webkit-outer-spin-button': { display: 'none' },
                                                            '&::-webkit-inner-spin-button': { display: 'none' },
                                                            '-moz-appearance': 'textfield',
                                                        },
                                                    }}
                                                    style={{ width: '100px' }}
                                                />

                                                {/* 加号按钮 */}
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => handleQuantityChange(item.product.sku, item.quantity + 1)}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </div>
                                        </TableCell>

                                        {/* 商品总价 */}
                                        <TableCell>
                                            <Typography variant="body1">
                                                ${(item.product.priceCAD * item.quantity).toFixed(2)}
                                            </Typography>
                                        </TableCell>

                                        {/* 删除按钮 */}
                                        <TableCell>
                                            <IconButton onClick={() => handleRemoveItem(item.product.sku)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>




                    //     cartItems.map((item) => (
                    // <div key={item.product.sku} style={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
                    //     <img src={item.product.image} alt={item.product.name} style={{ width: '80px', marginRight: '20px' }} />
                    //     <Typography variant="h6" style={{ flexGrow: 1 }}>
                    //         {item.product.name}
                    //     </Typography>


                    //     <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    //         {/* 减号按钮 */}
                    //         <IconButton
                    //             color="primary"
                    //             onClick={() => setQuantityToBuy((prev) => Math.max(prev - 1, 0))}
                    //         >
                    //             <RemoveIcon />
                    //         </IconButton>

                    //         {/* 数量输入框 */}
                    //         <TextField
                    //             type="number"
                    //             value={quantityToBuy}
                    //             onChange={(e) => {
                    //                 const value = Math.max(0, Math.min(Number(e.target.value), 10000)); // 限制值在 0 到 10000 之间
                    //                 setQuantityToBuy(value);
                    //             }}
                    //             onFocus={(e) => e.target.select()}  // 当用户点击输入框时，选中当前值，便于输入新值
                    //             inputProps={{
                    //                 min: 0,
                    //                 max: 10000, // 设置最大值为 10000
                    //                 style: { textAlign: 'center' },
                    //                 inputMode: 'numeric',  // 优化移动设备体验
                    //                 pattern: '[0-9]*',     // 仅允许数字输入
                    //                 sx: {
                    //                     // 禁用数字输入框的箭头
                    //                     '&::-webkit-outer-spin-button': {
                    //                         display: 'none',
                    //                     },
                    //                     '&::-webkit-inner-spin-button': {
                    //                         display: 'none',
                    //                     },
                    //                     // Firefox 的禁用样式
                    //                     '-moz-appearance': 'textfield',
                    //                 },
                    //             }}
                    //             style={{ width: '150px' }}
                    //         />

                    //         {/* 加号按钮 */}
                    //         <IconButton
                    //             color="primary"
                    //             onClick={() => setQuantityToBuy((prev) => prev + 1)}
                    //         >
                    //             <AddIcon />
                    //         </IconButton>
                    //     </div>


                    //     <IconButton onClick={() => handleRemoveItem(item.product.sku)}>
                    //         <DeleteIcon />
                    //     </IconButton>
                    // </div>
                    // ))
                )}
                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {cart.getTotalPrice() > 0 ? (
                        <Typography variant="h6">
                            Total: ${cart.getTotalPrice().toFixed(2)}
                        </Typography>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleShopnowClick}
                        >
                            Shop Now
                        </Button>
                    )}
                </div>
            </div>
            {cart.getTotalPrice() > 0 ? (<div style={{ display: 'flex', height: '83vh', justifyContent: 'space-between', backgroundColor: '#DDF4FF' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '88%', height: '74%', margin: 'auto' }}>
                    <div
                        style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            marginLeft: 'auto',
                            backgroundColor: 'white',
                            width: '45%',
                            aspectRatio: '1/1',  // 保持 1:1 的宽高比
                            maxWidth: '50vh',   // 保证容器不会超过视口的高度
                            maxHeight: '50vw',  // 保证容器不会超过视口的宽度
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '2px solid #adadac', // 添加边框
                        }}
                    >
                        <img
                            src={cart.product.image}
                            alt={cart.product.name}
                            style={{
                                marginTop: 'auto',
                                aspectRatio: '1/1',
                            }}
                        />
                        <div style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            height: '60px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '20px'
                        }}>
                            <img
                                src={HEART_HEALTH_black}
                                alt={'HEART_HEALTH_black'}
                                style={{
                                    aspectRatio: '1/1',
                                    width: '60px',
                                }}
                            />
                            <img
                                src={SLEEP_QUALITY_black}
                                alt={'SLEEP_QUALITY_black'}
                                style={{
                                    aspectRatio: '1/1',
                                    width: '60px',
                                }}
                            />
                            <img
                                src={ACTIVITY_TRACKING_black}
                                alt={'ACTIVITYTRACKING_black'}
                                style={{
                                    aspectRatio: '1/1',
                                    width: '60px',
                                }}
                            />
                            <img
                                src={FALL_DETECTION_black}
                                alt={'FALL_DETECTION_black'}
                                style={{
                                    aspectRatio: '1/1',
                                    width: '60px',
                                }}
                            />


                        </div>
                    </div>

                    <div style={{
                        gap: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        margin: 'auto',
                        width: '45%',
                        maxHeight: '50vw',  // 保证容器不会超过视口的宽度

                    }}>

                        <Typography
                            variant="h2"
                            component="h1"
                            gutterBottom
                            style={{
                                color: '#747474',
                                fontWeight: 'bold',
                                fontSize: '7vh',
                                textAlign: 'left',
                                width: '100%',

                            }}
                        >
                            {cart.product.name}
                        </Typography>
                        {/* 分割线 */}
                        <hr style={{
                            width: '100%',
                            border: 'none',
                            borderTop: '2px solid #adadac',  // 定制分割线样式
                            margin: '0px 0'
                        }} />
                        <Typography
                            variant="h2"
                            component="h1"
                            gutterBottom
                            style={{
                                color: '#747474',
                                fontWeight: 'bold',
                                fontSize: '4vh',
                                textAlign: 'left',
                                width: '100%',

                            }}
                        >
                            Price: ${cart.product.priceCAD}
                        </Typography>
                        <Typography
                            variant="h2"
                            component="h1"
                            gutterBottom
                            style={{
                                color: '#747474',
                                fontWeight: 'bold',
                                fontSize: '4vh',
                                textAlign: 'left',
                                width: '100%',

                            }}
                        >
                            SKU: {cart.product.sku}
                        </Typography>
                        <div style={{ display: "flex" }}>
                            <Typography
                                variant="h2"
                                component="h1"
                                gutterBottom
                                style={{
                                    color: '#747474',
                                    fontWeight: 'bold',
                                    fontSize: '4vh',
                                    textAlign: 'left',
                                    width: '100%',

                                }}
                            >
                                Quantity:
                            </Typography>

                        </div>

                        {/* 提示：已添加到购物车 */}
                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={3000} // 3 秒后自动隐藏
                            onClose={handleCloseSnackbar}
                        >
                            <Alert onClose={handleCloseSnackbar} severity="success">
                                Added to cart! Total items in cart: {totalQuantity}
                            </Alert>
                        </Snackbar>
                    </div>
                </div>
            </div>) : (<div></div>)}


        </div>




    );
};

export default CartPage;

import { createTheme } from '@mui/material/styles';

// 创建自定义主题
const theme = createTheme({
  typography: {
    // 设置全局字体
    fontFamily: 'ProximaNovaCond, Arial, sans-serif',
  },
  Container: {
    // 设置全局字体
    fontFamily: 'ProximaNovaCond, Arial, sans-serif',
  },
  // 你也可以自定义颜色、按钮样式等
//   palette: {
//     primary: {
//       main: '#1976d2',
//     },
//     secondary: {
//       main: '#dc004e',
//     },
//   },
});

export default theme;